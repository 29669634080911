import React from 'react'
// import CV from '../../media/resume.pdf'


const ButtonCV = () => (
    <a href='/resume.pdf' 
    download="Mateo Rios Resume.pdf" className='button is-info'>
        Descargar CV
    </a>
)

export default ButtonCV

// 'https://drive.google.com/uc?export=download&confirm=no_antivirus&id=1sSx7Ng8le26x9eovwfbStzbMERLpMXek'