import React from 'react'
import './Info.css'

const Info = () => {
  return (
    <div className='info-container'>
      <div className='info'>
        <h1>Trabajemos juntos y hagamos algo único</h1>
      </div>
      
    </div>
  )
}

export default Info
