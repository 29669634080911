import React from 'react'
import buscadorPeliculas from '../../media/proyectop.jpg'
import choiceBoard from '../../media/proyecto2.jpg'
import pythonSnake from '../../media/proyecto3.jpg'



const slidesInfo = [
  {
    src:
      buscadorPeliculas,
    alt: "Project 1",
    desc: "Buscador de Películas",
    href: 'http://buscador-peliculas-reactjs.surge.sh/',
  },
  {
    src:
      choiceBoard,
    alt: "Project 2",
    desc: "Choice Board Infantil",
    href: 'https://mateo903.github.io/choiceboard/'
  },
  {
    src:
      pythonSnake,
    alt: "Project 3",
    desc: "Snake Hecho en Python",
    href: 'https://github.com/Mateo903/PythonSnakePOO/blob/master/Python%20Snake%20POO.py'
  },
];

const slides = slidesInfo.map(slide => {
  return (
  <div className='slide-container'>
    <a href={slide.href} target='_blank' rel="noreferrer">
      <img src={slide.src} alt={slide.alt}/>
    </a>
    <div className='slide-desc'>
      <span>{slide.desc}</span>
    </div>
  </div>
)});

export default slides

// "https://cdn.pixabay.com/photo/2017/10/31/19/05/web-design-2906159_960_720.jpg",
// https://cdn.pixabay.com/photo/2015/02/05/08/06/macbook-624707_960_720.jpg
// "https://cdn.pixabay.com/photo/2016/12/19/08/39/mobile-phone-1917737_960_720.jpg"
// https://raw.githubusercontent.com/Mateo903/Portafolio/gh-pages/images/Perfil.jpeg