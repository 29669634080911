import React from 'react'
import './Slider.css'
import Carousel, { slidesToShowPlugin } from '@brainhubeu/react-carousel'
import '@brainhubeu/react-carousel/lib/style.css'
import Slides from './Slides'

const Slider = () => {
  return (
    <div className='carousel-container'>
      <div className='carousel-title'>
        <h2>Mis Proyectos</h2>
      </div>

      <Carousel
        // plugins={['arrows', 'infinite', 'centered', 'autoplay']}
        plugins={[ 'infinite', 'arrows',
          {
            resolve: slidesToShowPlugin,
            options: {
             numberOfSlides: 3
            }
          },
        ]}
        animationSpeed={500}
        offset={20}
        // itemWidth={400}
        slides={Slides}
        breakpoints={{
          960: {
            plugins:[{
              resolve: slidesToShowPlugin,
              options: {
               numberOfSlides: 1
              }
            }, ]
          }
        }}
      />
      
    </div>
  )
}

export default Slider
