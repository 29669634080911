import React, {useRef} from 'react'
import './App.css';
// import 'bulma/css/bulma.css'
import Load from './components/load/Load'
import Cover from './components/cover/Cover'
// import Navbar from './components/navbar/Navbar'
import About from './components/about/About'
import Slider from './components/slider/Slider'
import Info from './components/info/Info'
import Footer from './components/footer/Footer'

function App() {
  const aboutSection = useRef(null)
  const gotoAbout = () => window.scrollTo({top:aboutSection.current.offsetTop, behavior:'smooth'})

  // const [scrollHeight, setScrollHeight]=useState(0);

  // const handleScroll = () =>{
  //   const position = window.pageYOffset;
  //   setScrollHeight(position)
  // }

  // useEffect(() =>{
  //   window.addEventListener('scroll', handleScroll)
  // },[scrollHeight])

  return (
    <div className="App">

      <Load/>

      {/* <Navbar isScrolling={scrollHeight}/> */}
       
      <Cover handleClick={gotoAbout}/>
      

      <About referencia={aboutSection} />

      <Slider />

      <Info/>

      <Footer />
    </div>
  );
}

export default App;
