import React from 'react'
import './Cover.css'
import coverVideo from '../../media/coverVideo.mp4'
import Arrow from './Arrow'

const Cover = ({handleClick}) => {
  // const gotoAbout = () => window.scrollTo({top:prueba.current.offsetTop, behavior:'smooth'})


  return (
    <div className='cover-container'>
      <video className='video' src={coverVideo} autoPlay loop muted />
      <h1>MATEO RIOS</h1>
      <p>DESARROLLADOR FULL STACK</p> 
      <div className='arrow-container' onClick={handleClick}>
        <Arrow/>
      </div>
    </div>
  )
}

export default Cover
