import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-info'>
        <h1>Mateo Rios</h1>
        <p>Buenos Aires, Argentina</p>
      </div>
      <div className='footer-contact'>
        <h3>Contactame:</h3>
        <p>+54 9 3754441343</p>
        <p>mateo903@gmail.com</p>
      </div>
      <div className='footer-sns'>
        <div className='design-by'>
          Sitio construido por Mateo Rios
        </div>
        <div className='sns-links'>
          <a href="https://linkedin.com/in/mateorios/" target='_blank' rel="noreferrer">
            <i className='fab fa-linkedin linkedin'></i>
          </a>
          <a href="https://www.instagram.com/mateo.rios103/" target='_blank' rel="noreferrer">
            <i className='fab fa-instagram instagram'></i>
          </a>
          <a href="https://github.com/Mateo903" target='_blank' rel="noreferrer">
            <i className='fab fa-github github'></i>
          </a>
        </div>
      </div>
      
    </footer>
  )
}

export default Footer
