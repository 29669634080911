import React from 'react'
import './About.css';
import Picture from '../../media/perfilp.jpg'
import ButtonCV from './ButtonCV'

const About = ({referencia}) => {
  return (
    <div className='about-container' ref={referencia}>
      <div className='about-desc'>
        <h3>Sobre mi</h3>
        <p> Soy un apasionado de la tecnología, programo desde los 13 años de forma autodidacta, 
          y actualmente estoy cursando tecnicatura superior en programación. Cuento con experiencia en maquetado de sitios webs,
           desarrollo de aplicaciones y programación orientada a objetos.
       </p>
       <ButtonCV/>

      </div>
      <div className='about-img'>
        <img src={Picture}  alt='about' />

      </div>
      
    </div>
  )
}

export default About

// 'https://cdn.pixabay.com/photo/2015/01/08/18/29/entrepreneur-593358_960_720.jpg'
