import React , {useState, useEffect} from 'react'
import './Load.css'

const Load = () => {
  const [load, setLoad] = useState(true)

  useEffect(() =>{

    window.addEventListener('load', () => {setLoad(!load);console.log(load)})
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  if (load){return (
    <div className="centrado" id="onload">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    </div>
  )} else {
      return (<div></div>)
  } 

  
}

export default Load
