import React from 'react'
import './Arrow.css'

const Arrow = () => {
  return (
    <div className="arrow" ></div>
  )
}

export default Arrow
